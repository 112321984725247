




































import { defineComponent, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: false,
      default: {},
    },
  },
  setup(props, { root, emit }) {
    const model = reactive({
      startHour: "",
      endHour: "",
      sendMail: true,
    });

    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 405:
          return `${root.$tc("layout.errors.cantFindTopic")}`;
        case 406:
          return `${root.$tc("layout.errors.cantFindTimeFrame")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const acceptAbstract = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        abstract: props.item.id,
        status: "accepted",
        startHour: model.startHour || undefined,
        endHour: model.endHour || undefined,
        sendMail: model.sendMail,
      };

      state.loading = true;

      axiosInstance
        .put(`event/${root.$route.params.id}/abstract/active`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("panel.event.abstract.abstractAssignedToProgram"),
          });
          emit("fetch");
          emit("close");
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };
    return { model, acceptAbstract };
  },
});
